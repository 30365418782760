:root {
  --color-border: #e0e0e0;
  --menu-focus: #d0e0a2;
  --color-link: #ff5353;
  --marquee-bg: #4b4b4b;
  --marquee-text: #ff9432;
}
/* ------------------------- Menu Burger Button ----------------------------- */
#menu-burger {
  position: absolute;
  top: 0;
  right: 0;
  margin: 5rem;
  font-size: 3rem;
  padding: 0;
  display: inline-block;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #ffffff;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  backdrop-filter: blur(15px);
  background-color: rgba(0, 0, 0, 0.274);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

/* .menu.hidden {
  right: -100%;
  display: none;
} */

.menu a {
  padding: 0;
  color: white;
}

.menu a {
  color: white;
}

.menu__item {
  /* cursor: default; */
  position: relative;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 -1px var(--color-border);
}

.menu__item:last-child {
  box-shadow: 0 1px var(--color-border), 0 -1px var(--color-border);
}

.menu__item-link {
  display: block;
  position: relative;
  /* cursor: pointer; */
  text-decoration: none;
}

.menu__item-link:focus,
.menu__item-link:focus-visible {
  color: var(--menu-focus);
}

.menu__item-link:focus:not(:focus-visible) {
  color: var(--color-link);
}

.marquee {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: var(--marquee-bg);
  transform: translate3d(0, 101%, 0);
}

.marquee__inner-wrap {
  height: 100%;
  width: 100%;
  transform: translate3d(0, -101%, 0);
}

.marquee__inner {
  height: 100%;
  width: fit-content;
  align-items: center;
  display: flex;
  position: relative;
  animation: marquee 15s linear infinite;
  will-change: transform;
}

@keyframes marquee {
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.menu__item-link,
.marquee span {
  white-space: nowrap;
  font-size: 6vw;
  line-height: 1.2;
  font-weight: 600;
  padding: 1vh 1vw 0;
  text-transform: uppercase;
}

.marquee span {
  text-align: center;
  color: var(--marquee-text);
  font-weight: 400;
}

.marquee__img {
  width: 15vw;
  height: 70%;
  margin: 0 2vw;
  border-radius: 5vw;
  background-size: cover;
  background-position: 50% 50%;
}
